.Rtn {
    color: green;
    padding: 3px;
}
.Almhigh {
    color: orange;
    padding: 3px;
}
.AlmLow {
    color: rgb(109, 109, 33);
    padding: 3px;
}
.AlmEmg {
    color: red;
    padding: 3px;
}
.RtnBlink {
    color: green;
    animation: blinker 2s linear infinite;
}
.AlmHighBlink {
    color: orange;
    padding: 3px;
    animation: blinker 2s linear infinite;
}
.AlmLowBlink {
    color: rgb(109, 109, 33);
    padding: 3px;
    animation: blinker 2s linear infinite;
}
.AlmEmgBlink {
    color: red;
    padding: 3px;
    animation: blinker 2s linear infinite;
}

.blink {
    /* color: #FFF; */
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    0% {
        opacity: 1.0
    }
    50% {
        opacity: 0.0
    }
    100% {
        opacity: 1.0;
    }
}
