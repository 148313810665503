.buttonA {
    /* background-color: Crimson;   */
    border-radius: 5px;
    color: white;
    padding: .5em;
    text-decoration: none;
    
  }
  
  .buttonA:focus,
  .buttonA:hover {
    /* background-color: FireBrick; */
    color: White;
  }

.alarmTable {
    color: #FFF;
    width: 100%;
    min-width: 500;
    font-family: Arial, Helvetica, sans-serif;
}
.alarmTable tr{
    height: 20px;
}
.alarmTable th {
    font-size: 0.8em;
    padding: 0px 0px 0px 5px;
}
.headtd {
    color: white !important;
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
}
.rtn {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: green !important;
}
.almhigh {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: orange !important;
}
.almLow {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: rgb(109, 109, 33) !important;
}
.almEmg {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: red !important;
}
.rtnBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: green !important;
    animation: blinker 1s linear infinite;
}
.almHighBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: orange !important;
    animation: blinker 1s linear infinite;
}
.almLowBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: rgb(109, 109, 33) !important;
    animation: blinker 1s linear infinite;
}
.almEmgBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: red !important;
    animation: blinker 1s linear infinite;
}


.blink {
    /* color: #FFF; */
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    0% {
        opacity: 1.0
    }
    50% {
        opacity: 0.0
    }
    100% {
        opacity: 1.0;
    }
}

.columnResizer {
    width: 0.3px;
}

