.grid-block {
    display: inline;
    max-height: '800px';
     
    background-color: black; 
  }

  
.buttonA {
  /* background-color: Crimson;   */
  border-radius: 3px;
  color: white;
  padding: .3em;
  text-decoration: none;
  
}

.buttonA:focus,
.buttonA:hover {
  /* background-color: FireBrick; */
  color: White;
}