/* ul{
    list-style-type: none;
    display: inline;
}
li{
    display: inline;
}  */
/*
.navWrapper {
    width:500px;
    height:500px;
    color:black;
}
.menu-holder ul {
    margin-top: 100px;
    padding: 0;
    list-style-type: none;
}
.menu-holder ul li {
    position: relative;
    float: left;
    padding: 0px 10px 0 10px;
    margin: 0px 0px 100px 0px;
    border-left: 1px dotted white;
}
   
.menu-holder ul li:hover{
    background-color: green;
}

.menu-holder ul li:hover > ul {
    display: block;
}
 .menu-holder ul li.current-menu-item
{
    background:#999
}

.menu-holder ul li a {
    font-family: arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    display: block;
    color:black;
    text-decoration: none;
    padding: 15px 10px 15px 10px;
    -webkit-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
}
.menu-holder ul li ul {
    float: none;
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    margin: -1px 0 0px 10px;
    padding: 5px 10px 5px 10px;
    white-space: nowrap;
}
.menu-holder ul li ul li {
    position: static;
    float: none;
    display: inline-flex;
    padding: 5px 10px 5px 10px;
    margin: 0px 0px 0px -10px;
    background-color: #025179;
}
.menu-holder ul li ul li a {
    display: inline;
    margin: 0 0px 0 0px;
    padding: 0px 10px 0px 10px;
    font-weight: normal;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.menu-holder ul li ul li:first-of-type {
    -webkit-border-radius: 0px 0px 0px 5px;
    border-radius: 0px 0px 0px 5px;
}
.menu-holder ul li ul li:last-of-type {
    -webkit-border-radius: 0px 5px 5px 0px;
    border-radius: 0px 5px 5px 0px;
}
.menu-holder ul li:first-of-type {
    border-left: none;
}
 */

/* .dropdown-menu {
    height: 35px;
    list-style: none;
    margin: 10px;
    padding: 0;
    float: left;
    text-align: center;
}

.dropdown-menu li {
    display: inline-block;
    position: relative;
    float: left;
    width: 200px;
    line-height: 35px;
    text-decoration: none;
    background-color: brown;
}


.dropdown-menu li li a {
    font-size: 12px;
}

.dropdown-menu li:hover {
    background: blue;
}

/*--- Sublist Styles ---*
.dropdown-menu ul {
    position: absolute;
    display: none;
}

/*--- Hide Sub Sublists ---*
.dropdown-menu li:hover ul ul {
    display: none;
}

/*--- Sublevel UL's display and position on hover ---*
.dropdown-menu li:hover ul {
    display: block;
}
.dropdown-menu li li:hover ul {
    margin-left: 200px;/*This must be width of menu box*
    margin-top: -35px;
    display: block;
}
ul li {
    padding: 10px;
}

.dropdown-submenu {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: blue;
}  */

/* ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  li {
    float: left;
  }
  
  li a, .dropbtn {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  li a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  li.dropdown {
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  .dropdown:hover .dropdown-content {
    display: block;
  } */

.menu {
  /* font-family: Roboto, 'Helvetica Neue', Arial, sans-serif; */
  font-family: Source Sans Pro, 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.98em;
  -webkit-flex: 1 1;
          flex: 1 1;
  font-weight: 550;
}
.parent {
  display: block;
  position: relative;
  float: left;
  line-height: 34px;
  background-color: rgba(238, 238, 238, 0.15);
  border-right: #bbb 2px solid;
  padding: 0px 8px;
}
.parent a {
  margin: 10px;
  color: #ffffff;
  text-decoration: none;
}
.parent:hover > ul {
  display: block;
  position: absolute;
}
.child {
  display: none;
}
.child li {
  background-color: rgb(0, 153, 204);
  line-height: 36px;
  border-bottom: #ccc 1px solid;
  border-right: #ccc 1px solid;
  width: 100%;
  z-index: 9999;
}
.child li a {
  color: #fff;
  z-index: 9999;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0px;
  min-width: 10em;
  z-index: 9999;
}
ul ul ul {
  left: 100%;
  top: 0;
  margin-left: 1px;
}
li:hover {
  background-color: rgb(2, 77, 102);
  z-index: 9999;
}
.parent li:hover {
  background-color: rgb(2, 77, 102);
  /* background-color: #32bcd1; */
  z-index: 9999;
}
.expand {
  font-size: 12px;
  float: right;
  z-index: 9999;
  z-index: 9999;
} /* margin-right:5px;*/
.active {
  color: #fff !important;
  font-weight: 600;
  border-bottom: 1px solid #fff;
}

.Header-appBar-136 {
  height: auto !important;
}

.buttonA {
    /* background-color: Crimson;   */
    border-radius: 5px;
    color: white;
    padding: .5em;
    text-decoration: none;
    
  }
  
  .buttonA:focus,
  .buttonA:hover {
    /* background-color: FireBrick; */
    color: White;
  }

.alarmTable {
    color: #FFF;
    width: 100%;
    min-width: 500;
    font-family: Arial, Helvetica, sans-serif;
}
.alarmTable tr{
    height: 20px;
}
.alarmTable th {
    font-size: 0.8em;
    padding: 0px 0px 0px 5px;
}
.headtd {
    color: white !important;
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
}
.rtn {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: green !important;
}
.almhigh {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: orange !important;
}
.almLow {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: rgb(109, 109, 33) !important;
}
.almEmg {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: red !important;
}
.rtnBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: green !important;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}
.almHighBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: orange !important;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}
.almLowBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: rgb(109, 109, 33) !important;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}
.almEmgBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: red !important;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}


.blink {
    /* color: #FFF; */
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1.0
    }
    50% {
        opacity: 0.0
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes blinker {
    0% {
        opacity: 1.0
    }
    50% {
        opacity: 0.0
    }
    100% {
        opacity: 1.0;
    }
}

.columnResizer {
    width: 0.3px;
}


.alarmTable {
    color: #FFF;
    width: 100%;
    min-width: 500;
    font-family: Arial, Helvetica, sans-serif;
}
.alarmTable tr{
    height: 20px;
}
.alarmTable th {
    font-size: 0.8em;
    padding: 0px 0px 0px 5px;
}
.headtd {
    color: white !important;
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
}
.rtn {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: green !important;
}
.almhigh {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: orange !important;
}
.almLow {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: rgb(109, 109, 33) !important;
    /* rgb(170, 170, 7) */
}
.almEmg {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: red !important;
}
.rtnBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: green !important;
    /*animation: blinker 1s linear infinite;*/
}
.almHighBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: orange !important;
    /*animation: blinker 1s linear infinite;*/
}
.almLowBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: rgb(109, 109, 33) !important;
    /*animation: blinker 1s linear infinite;*/
}
.almEmgBlink {
    font-size: 0.8em !important;
    padding: 0px 0px 0px 5px;
    color: red !important;
    /*animation: blinker 1s linear infinite;*/
}


/*.blink {
    animation: blinker 1s linear infinite;
}*/

/*@keyframes blinker {
    0% {
        opacity: 1.0
    }
    50% {
        opacity: 0.0
    }
    100% {
        opacity: 1.0;
    }
}*/

.columnResizer {
    width: 0.3px;
}

/* .gm-style .gm-style-iw-c {
  color: white;
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: rgb(17, 43, 53);
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
} */

#propertymap .gm-style-iw {
  background-color: #252525;
  box-shadow: none;
  color: #515151;
  font-family: 'Georgia', 'Open Sans', Sans-serif;
  text-align: center;
  width: 100% !important;
  border-radius: 0;
  left: 0 !important;
  top: 20px !important;
}

/* #propertymap .gm-style-iw {
  background-color: #252525;
  padding: 2% 11%;
} */

#propertymap .gm-style > div > div > div > div > div > div > div {
  background: none !important;
}

.gm-style > div > div > div > div > div > div > div:nth-child(2) {
  box-shadow: none !important;
}
/* #propertymap .gm-style-iw > div > div {
  background: #fff !important;
} */

#propertymap .gm-style-iw a {
  text-decoration: none;
}

#propertymap .gm-style-iw > div {
  width: 245px !important;
}

#propertymap .gm-style-iw .img_wrapper {
  height: 150px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin: 0px auto;
}

#propertymap .gm-style-iw .img_wrapper > img {
  width: 100%;
  height: auto;
}

#propertymap .gm-style-iw .property_content_wrap {
  padding: 0px 20px;
}

#propertymap .gm-style-iw .property_title {
  min-height: auto;
  -webkit-align-items: center;
          align-items: center;
}

.buttonA {
  /* background-color: Crimson;   */
  border-radius: 5px;
  color: white;
  padding: .5em;
  text-decoration: none;
  
}

.buttonA:focus,
.buttonA:hover {
  /* background-color: FireBrick; */
  color: White;
}
/* .iwTable {
  color: #151719;
  font-size: 14px;
  width: 100%;
}

.iwTable th,
.iwTable td {
  padding: 5px;
  text-align: left;
  border: 1px solid #999;
}

.iwTable tr:hover {
  background-color: #f5f5f5;
}
.iwTable tr:nth-child(even) {
  background-color: #f2f2f2;
} */

/* CSS for button */
.infoWindButton {
  float: right;
  margin-top: 5px;
  transition-duration: 0.3s;
  background-color: #444;
  padding: 5px 10px;
  border-radius: 6px;
  color: white;
  opacity: 0.9;
}

.infoWindButton:hover {
  opacity: 1;
}

.searchBox {
  position: relative;
  z-index: 999;
  float: left;
  width: 14%;
  margin: 5px;
}

.blink {
  width: 200px;
  height: 50px;
  padding: 15px;
  text-align: center;
  line-height: 50px;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* .gm-style .gm-style-iw {
    background-color: #252525 !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 130px;
    padding-top: 10px;
    display: block !important;
  } */

/*style the p tag*/

/* .gm-style .gm-style-iw #google-popup p {
    padding: 10px;
  } */

/*style the arrow*/
/* .gm-style div div div div div div div div {
    background-color: #252525 !important;
    padding: 0;
    margin: 0;
    padding: 0;
    top: 0;
    color: #fff;
    font-size: 12px;
  } */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f1f1ff;
  font-family: 'Nunito', sans-serif;
}

.btn {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: #fff;
  color: #333;
  border-radius: 50%;
  text-decoration: none;
  font-size: 1.2em;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

#sidenav {
  width: 80%;
  max-width: 300px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: linear-gradient(to right, #673ab7, #5e35b1);
  left: -300px;
  transition: left 0.35s;
  font-family: Source Sans Pro, 'Helvetica Neue', Arial, sans-serif;
}

.sidenav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 100%;
  top: 0;
  z-index: -1;
  cursor: default;
}

#sidenav:target {
  left: 0;
}

#sidenav:target + .sidenav-overlay {
  left: 0;
}

ul {
  list-style: none;
  height: 100%;
  overflow: auto;
}

.center {
  text-align: center;
}

.user {
  padding: 20px;
  position: relative;
}

.user img {
  width: 150px;
  border-radius: 50%;
}

.user p {
  color: #eee;
  padding: 10px 0;
}

.divider {
  padding: 0;
  height: 1px;
  background: #7e57c2;
}

.title {
  color: #b39ddb;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 1px;
  padding: 20px;
  padding-bottom: 10px;
}

.item {
  background: transparent;
  transition: background 0.35s;
}

.item a {
  text-decoration: none;
  color: #eee;
  display: inline-block;
  padding: 20px;
  padding-left: 30px;
  width: 100%;
}

.item.active,
.item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
  background: #673ab7;
  color: #fff;
  left: 0;
  padding: 10px 20px;
  z-index: -2;
}

a:focus {
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

.glg_wrapper {
    width: 100%;
    padding: 5px;
    height: 900px;
}
.Rtn {
    color: green;
    padding: 3px;
}
.Almhigh {
    color: orange;
    padding: 3px;
}
.AlmLow {
    color: rgb(109, 109, 33);
    padding: 3px;
}
.AlmEmg {
    color: red;
    padding: 3px;
}
.RtnBlink {
    color: green;
    -webkit-animation: blinker 2s linear infinite;
            animation: blinker 2s linear infinite;
}
.AlmHighBlink {
    color: orange;
    padding: 3px;
    -webkit-animation: blinker 2s linear infinite;
            animation: blinker 2s linear infinite;
}
.AlmLowBlink {
    color: rgb(109, 109, 33);
    padding: 3px;
    -webkit-animation: blinker 2s linear infinite;
            animation: blinker 2s linear infinite;
}
.AlmEmgBlink {
    color: red;
    padding: 3px;
    -webkit-animation: blinker 2s linear infinite;
            animation: blinker 2s linear infinite;
}

.blink {
    /* color: #FFF; */
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1.0
    }
    50% {
        opacity: 0.0
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes blinker {
    0% {
        opacity: 1.0
    }
    50% {
        opacity: 0.0
    }
    100% {
        opacity: 1.0;
    }
}

.glg_wrapper {
    width: 100%;
    padding: 5px;
    height: 900px;
}
.grid-block {
    display: inline;
    max-height: '800px';
     
    background-color: black; 
  }

  
.buttonA {
  /* background-color: Crimson;   */
  border-radius: 3px;
  color: white;
  padding: .3em;
  text-decoration: none;
  
}

.buttonA:focus,
.buttonA:hover {
  /* background-color: FireBrick; */
  color: White;
}
