/* ul{
    list-style-type: none;
    display: inline;
}
li{
    display: inline;
}  */
/*
.navWrapper {
    width:500px;
    height:500px;
    color:black;
}
.menu-holder ul {
    margin-top: 100px;
    padding: 0;
    list-style-type: none;
}
.menu-holder ul li {
    position: relative;
    float: left;
    padding: 0px 10px 0 10px;
    margin: 0px 0px 100px 0px;
    border-left: 1px dotted white;
}
   
.menu-holder ul li:hover{
    background-color: green;
}

.menu-holder ul li:hover > ul {
    display: block;
}
 .menu-holder ul li.current-menu-item
{
    background:#999
}

.menu-holder ul li a {
    font-family: arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    display: block;
    color:black;
    text-decoration: none;
    padding: 15px 10px 15px 10px;
    -webkit-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
}
.menu-holder ul li ul {
    float: none;
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    margin: -1px 0 0px 10px;
    padding: 5px 10px 5px 10px;
    white-space: nowrap;
}
.menu-holder ul li ul li {
    position: static;
    float: none;
    display: inline-flex;
    padding: 5px 10px 5px 10px;
    margin: 0px 0px 0px -10px;
    background-color: #025179;
}
.menu-holder ul li ul li a {
    display: inline;
    margin: 0 0px 0 0px;
    padding: 0px 10px 0px 10px;
    font-weight: normal;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.menu-holder ul li ul li:first-of-type {
    -webkit-border-radius: 0px 0px 0px 5px;
    border-radius: 0px 0px 0px 5px;
}
.menu-holder ul li ul li:last-of-type {
    -webkit-border-radius: 0px 5px 5px 0px;
    border-radius: 0px 5px 5px 0px;
}
.menu-holder ul li:first-of-type {
    border-left: none;
}
 */

/* .dropdown-menu {
    height: 35px;
    list-style: none;
    margin: 10px;
    padding: 0;
    float: left;
    text-align: center;
}

.dropdown-menu li {
    display: inline-block;
    position: relative;
    float: left;
    width: 200px;
    line-height: 35px;
    text-decoration: none;
    background-color: brown;
}


.dropdown-menu li li a {
    font-size: 12px;
}

.dropdown-menu li:hover {
    background: blue;
}

/*--- Sublist Styles ---*
.dropdown-menu ul {
    position: absolute;
    display: none;
}

/*--- Hide Sub Sublists ---*
.dropdown-menu li:hover ul ul {
    display: none;
}

/*--- Sublevel UL's display and position on hover ---*
.dropdown-menu li:hover ul {
    display: block;
}
.dropdown-menu li li:hover ul {
    margin-left: 200px;/*This must be width of menu box*
    margin-top: -35px;
    display: block;
}
ul li {
    padding: 10px;
}

.dropdown-submenu {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: blue;
}  */

/* ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  li {
    float: left;
  }
  
  li a, .dropbtn {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  li a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  li.dropdown {
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  .dropdown:hover .dropdown-content {
    display: block;
  } */

.menu {
  /* font-family: Roboto, 'Helvetica Neue', Arial, sans-serif; */
  font-family: Source Sans Pro, 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.98em;
  flex: 1 1 0%;
  font-weight: 550;
}
.parent {
  display: block;
  position: relative;
  float: left;
  line-height: 34px;
  background-color: rgba(238, 238, 238, 0.15);
  border-right: #bbb 2px solid;
  padding: 0px 8px;
}
.parent a {
  margin: 10px;
  color: #ffffff;
  text-decoration: none;
}
.parent:hover > ul {
  display: block;
  position: absolute;
}
.child {
  display: none;
}
.child li {
  background-color: rgb(0, 153, 204);
  line-height: 36px;
  border-bottom: #ccc 1px solid;
  border-right: #ccc 1px solid;
  width: 100%;
  z-index: 9999;
}
.child li a {
  color: #fff;
  z-index: 9999;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0px;
  min-width: 10em;
  z-index: 9999;
}
ul ul ul {
  left: 100%;
  top: 0;
  margin-left: 1px;
}
li:hover {
  background-color: rgb(2, 77, 102);
  z-index: 9999;
}
.parent li:hover {
  background-color: rgb(2, 77, 102);
  /* background-color: #32bcd1; */
  z-index: 9999;
}
.expand {
  font-size: 12px;
  float: right;
  z-index: 9999;
  z-index: 9999;
} /* margin-right:5px;*/
.active {
  color: #fff !important;
  font-weight: 600;
  border-bottom: 1px solid #fff;
}

.Header-appBar-136 {
  height: auto !important;
}
